.Welcome__container___1RlvM {
  text-align: center;
}

.Welcome__banner___2-rUf {
  background: url(/3f65c91d289e0dd34b534925fb980d89.png);
  background-size: cover;
  width: 100%;
  height: 500px;
  margin-bottom: -25px;
}

.Welcome__subtitle___3qDd4 {
  margin-top: 5px;
}
