.Spinner__full_height___2zYUn {
  height: 100vh;
}

.Spinner__fixed_height___2x9R4 {
  height: 300px;
}

.Spinner__full_height___2zYUn,
.Spinner__fixed_height___2x9R4 {
  display: flex;
  align-items: center;
}
