.Navbar__header___2_J29 {
  font-style: italic;
}

.Navbar__header___2_J29,
.Navbar__header___2_J29:hover,
.Navbar__link___1RvSQ,
.Navbar__link___1RvSQ:hover {
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}
