.LoginPage__container___3ualm {
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.LoginPage__form___3xOvT {
  width: 400px;
  padding-right: 90px;
}

.LoginPage__illustration___10PKw {
  background: url(/c4a0c5e6b0b3567ed87117a2e6514570.png);
  background-size: cover;
  width: 50%;
  height: 500px;
}
