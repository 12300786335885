.ChangePasswordPage__container___J69-_ {
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ChangePasswordPage__form___6asp9 {
  width: 400px;
  padding-right: 90px;
}

.ChangePasswordPage__illustration___3v1mM {
  background: url(/b0807790f5bdbf446ccec7d6bd700a71.png);
  background-size: cover;
  width: 50%;
  height: 500px;
}
