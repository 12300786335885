.ProjectSetupModal__toolbar___3ck8s {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProjectSetupModal__button_container___2goUQ {
  min-width: 500px;
}

.ProjectSetupModal__code___3upsY {
  padding: 10px;
  font-family: Monaco, Menlo, Consolas, Courier New, monospace;
}
